import { Link, Text, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../utils/constants";

export const useAuth = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const toast = useToast();

  const login = async (email: string, password: string, redirectUri?: string | null) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${API_URL}/api/v1/auth/token`, {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          grant_type: "",
          username: email,
          password: password,
          scope: "",
          client_id: "",
          client_secret: "",
        }),
      });

      if (!response.ok) {
        // TODO throw a custom error when the warehouse is not ready
        if (response.status === 500) {
          // sleep for 5 seconds until the warehouse is ready
          await new Promise((resolve) => setTimeout(resolve, 5000));
          login(email, password);
        }
        throw new Error("Invalid credentials");
      }

      const data = await response.json();

      if (data.access_token) {
        // Fetch user data using the token in header
        const userResponse = await fetch(`${API_URL}/api/v1/user/me`, {
          method: "GET",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${data.access_token}`,
          },
        });
        const userData = await userResponse.json();

        // Set secure cookie with the token
        document.cookie = `auth_token=${data.access_token}; path=/; secure; samesite=strict`;

        // Store user data in localStorage (but not the token)
        localStorage.setItem("full_name", userData.full_name);
        localStorage.setItem("email", userData.email);
        localStorage.setItem("phone_number", userData.phone_number);
        localStorage.setItem(
          "organization_id",
          userData.user_organizations[0].organization_id
        );
        localStorage.setItem(
          "organization",
          userData.user_organizations[0].organization.name
        );
        localStorage.setItem("role_id", userData.user_organizations[0].role_id);
        
        // Handle redirect based on redirectUri
        if (redirectUri) {
          // Check if redirect is cross-domain
          const isCrossDomain = new URL(redirectUri).origin !== window.location.origin;
          
          if (isCrossDomain) {
            // Use hash for cross-domain with token
            window.location.href = `${redirectUri}#access_token=${data.access_token}`;
          } else {
            // Keep using secure cookie for same-domain
            window.location.href = redirectUri;
          }
        } else {
          navigate("/Home");
          window.location.reload();
        }
      } else {
        throw new Error("Invalid token response");
      }
    } catch (error: any) {
      setError(error.message);
      toast({
        title: "Login failed.",
        description: (
          <Text>
            Please check your email and password. If you don't have an account,{" "}
            <Link
              href="https://chatworks.io/contact-us/"
              color="brand"
              textDecoration="underline"
            >
              contact us
            </Link>
            .
          </Text>
        ),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return { login, error, loading };
};
