import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { IntegrationsSourcesTable } from "./IntegrationsSourcesTable";
import { NewIntegration } from "./NewIntegration";

export const IntegrationsTable = () => {
  return (
    <Flex direction="column" minH="80vh" minW="130vh">
      <Flex
        justify="space-between"
        align="center"
        p={4}
        borderBottom="1px solid #e2e8f0"
      >
        <Text fontSize="xl" fontWeight="bold">
          
        </Text>
        <Button colorScheme="brand">
          <NewIntegration />
        </Button>
      </Flex>
      
      <Flex flex="1">
        <Box flex="1">
          <IntegrationsSourcesTable />
        </Box>
      </Flex>
    </Flex>
  );
};
