import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { AgentVersion as AgentVersionType } from "../client/types";
import { API_URL } from "../utils/constants";
import { loadToken } from "../utils/validateToken";

export const useDeleteAgentVersion = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const toast = useToast();
  const [wasDeleted, setWasDeleted] = useState<boolean>(false); // Local state to store deletion status

  const deleteAgentVersion = async (agentId: string, version_id: string) => {
    setLoading(true);
    setError(null);
    try {
      const token = loadToken() || "";
      const versionResponse = await fetch(
        `${API_URL}/api/v1/agents/versions/${version_id}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      );
      const agentVersion: AgentVersionType = await versionResponse.json();
      if (!versionResponse.ok) {
        throw new Error("Failed to fetch agent version");
      }
      if (agentVersion.is_default) {
        throw new Error("Cannot delete default agent version");
      }
      const response = await fetch(
        `${API_URL}/api/v1/agents/versions/${version_id}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete agent version");
      }

      setWasDeleted(true);
      return true;
    } catch (error: any) {
      setError(error.message);
      toast({
        title: "Failed to delete agent",
        description: error.message || "Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return false;
    } finally {
      setLoading(false);
    }
  };

  return { deleteAgentVersion, wasDeleted, loading, error };
};
