import { useEffect, useState } from 'react';

export type UserRole = 'USER' | 'ADMIN' | 'SUPER_ADMIN';

export const useRoleAuth = () => {
  const [role, setRole] = useState<UserRole>('USER');

  useEffect(() => {
    const storedRole = localStorage.getItem('role_id');
    if (storedRole) {
      setRole(storedRole as UserRole);
    }
  }, []);

  const hasPermission = (requiredRoles: UserRole[]) => {
    if (!requiredRoles.length) return true;
    
    // If user is SUPER_ADMIN, they have access to everything including ADMIN features
    if (role === 'SUPER_ADMIN') {
      return true;
    }
    
    // If user is ADMIN, they should have access to ADMIN features
    if (role === 'ADMIN' && requiredRoles.some(r => ['ADMIN', 'USER'].includes(r))) {
      return true;
    }
    
    // Otherwise, they only have USER permissions
    return requiredRoles.includes('USER');
  };

  const isAdmin = () => hasPermission(['ADMIN']);
  const isSuperAdmin = () => hasPermission(['SUPER_ADMIN']);

  return {
    role,
    hasPermission,
    isAdmin,
    isSuperAdmin
  };
};
