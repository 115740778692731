import { useToast } from "@chakra-ui/react";
import { useEffect, useState, useCallback } from "react";
import { API_URL } from "../utils/constants";
import { loadToken } from "../utils/validateToken";
export const useListAgents = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [agents, setAgents] = useState<any[]>([]); // Local state to store fetched agents
  const toast = useToast();

  // FIXME - hardcoded organization_id for now, create a route to get the organization_id
  const organization_id = localStorage.getItem("organization_id");

  const listAgents = useCallback(async () => {
    if (agents.length > 0) {
      // If agents are already loaded, no need to fetch again
      return agents;
    }

    setLoading(true);
    setError(null);
    try {
      const token = loadToken() || "";
      if (!token) {
        throw new Error("Session expired");
      }

      const response = await fetch(
        `${API_URL}/api/v1/agents?organization_id=${organization_id}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      if (response.status === 204) {
        setError("No agents found");
        return [];
      }
      if (!response.ok) {
        throw new Error("Failed to get agents");
      }

      const data = await response.json();
      setAgents(data); // Cache the data in local state
      localStorage.setItem("agents", JSON.stringify(data)); // Cache the data in local storage
      return data;
    } catch (error: any) {
      if (error.message !== "No agents found") {
        setError(error.message);
        toast({
          title: "Failed to get agents",
          description: error.message || "Please try again.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
      // window.location.reload();
    } finally {
      setLoading(false);
    }
  }, [agents, setAgents, setLoading, setError, toast, organization_id]);

  // Optional useEffect to automatically fetch agents if not loaded yet
  useEffect(() => {
    if (agents.length === 0) {
      listAgents(); // Fetch agents if not already cached
    }
  }, [agents.length, listAgents]); // Add dependencies for proper refetching

  return { listAgents, error, loading, agents }; // Expose agents for access in the component
};
