import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { API_URL } from "../utils/constants";
import { loadToken } from "../utils/validateToken";

export const useDeleteAgentFile = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const toast = useToast();

  const deleteFile = async (agentId: string, file_id: string) => {
    setLoading(true);
    setError(null);
    try {
      const token = loadToken() || "";
      const response = await fetch(
        `${API_URL}/api/v1/agents/${agentId}/files/${file_id}?token=${token}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete file");
      }

      return true;
    } catch (error: any) {
      setError(error.message);
      toast({
        title: "Failed to delete file",
        description: error.message || "Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return false;
    } finally {
      setLoading(false);
    }
  };

  return { deleteFile, loading, error };
};
