import { Flex, Text } from "@chakra-ui/react";
import { AgentsPlayground } from "./AgentsPlayground";

export const AdminSettings = () => (
  <Flex direction="column" minH="80vh" minW="135vh">
    <Flex
      justify="space-between"
      align="center"
      p={4}
      borderBottom="1px solid #e2e8f0"
    >
      <Text fontSize="xl" fontWeight="bold">
        Account Settings
      </Text>
    </Flex>

    <Flex direction="column" minH="80vh" width="100%">
      <Flex flex="1">
        <AgentsPlayground />
      </Flex>
    </Flex>
  </Flex>
);
