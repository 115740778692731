import { API_URL } from "../utils/constants";
import { Agent } from "../client/types";
import { useToast } from "@chakra-ui/react";
import { useState, useEffect, useCallback } from "react";
import { loadToken } from "../utils/validateToken";

export const useGetAgent = (agentId: string) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const toast = useToast();

  const token = loadToken() || "";

  const [agent, setAgent] = useState<Agent | null>(null); // Local state to store fetched agent

  const getAgent = useCallback(async () => {
    if (agent) {
        // If agent is already loaded, no need to fetch again
        return agent;
        }
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${API_URL}/api/v1/agents/${agentId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch agent");
      }

      const data = await response.json();
      setAgent(data);
      return data;
    } catch (error: any) {
      setError(error.message);
      toast({
        title: "Failed to get agent",
        description: error.message || "Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }, [agentId, token, agent, setAgent, setLoading, setError, toast]);

  useEffect(() => {
    if (!agent) {
      getAgent(); // Fetch agents if not already cached
    }
  }, [agent, getAgent]);

  return { getAgent, agent, loading, error };
};
