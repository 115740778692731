import { Flex } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { AgentHome } from "../components/Agent/AgentHome";
import { SideBar } from "../components/Sidebar/SideBar";

export const Agent = () => {
  // Keeping agentId and mode for future use
  const { /* agentId, mode, */ assistant_id } = useParams<{
    // agentId: string;
    // mode: string;
    assistant_id: string;
  }>();
  return (
    <Flex direction="row" minH="100vh" minW="50vw">
      <Flex flex="1">
        <SideBar agent_version={assistant_id ?? ""}/>
      </Flex>
      <Flex flex="4" justify="left" maxW="100vw">
        <AgentHome />
      </Flex>
    </Flex>
  );
};
