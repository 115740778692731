import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { AgentVersion } from "../../client/types";
import { useCreateAgentVersion } from "../../hooks/useCreateAgentVersion";
import { useGetAgent } from "../../hooks/useGetAgent";
import { useGetAgentVersion } from "../../hooks/useGetAgentVersion";
import { useListAgentsVersions } from "../../hooks/useListAgentsVersions";
import { useListEngines } from "../../hooks/useListEngines";

export const NewVersion = ({
  agentId,
  onNewAgentVersion,
}: {
  agentId: string;
  onNewAgentVersion: (agent: AgentVersion) => void;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const { createAgentVersion, loading } = useCreateAgentVersion();

  // Fetch the list of agent versions
  const { agentVersions } = useListAgentsVersions(agentId);
  const { agent } = useGetAgent(agentId);
  const { engines } = useListEngines();
  const engine = engines.find((engine) => engine.id === agent?.engine.id);
  const engine_instructions = engine?.instructions;

  // State to manage form inputs and selected version
  const [versionName, setVersionName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedVersionId, setSelectedVersionId] = useState<string | null>(
    null
  );
  const [selectedVersionData, setSelectedVersionData] = useState<any>(null);

  const { getAgentVersion } = useGetAgentVersion(
    agentId,
    selectedVersionId === "engine-prompt" ? "" : selectedVersionId || ""
  );
  const latestVersion = agentVersions.find((v) => v.is_default) || {};
  // Fetch the selected version's data when selectedVersionId changes
  useEffect(() => {
    const fetchVersionData = async () => {
      if (selectedVersionId && selectedVersionId !== "engine-prompt") {
        try {
          const versionData = await getAgentVersion();
          setSelectedVersionData(versionData);
        } catch (error) {
          console.error("Error fetching version:", error);
        }
      }
      if (selectedVersionId === "engine-prompt") {
        setSelectedVersionData(engine_instructions);
      }
    };
    fetchVersionData();
  }, [selectedVersionId, getAgentVersion, engine_instructions]);

  const handleSave = async () => {
    try {
      // Create the new agent version with either default instructions or copied instructions
      const versionData = {
        agent_id: agentId,
        name: versionName,
        description: description,
        is_default: false,
        instructions: selectedVersionData.instructions,
      };

      const newAgentVersion = await createAgentVersion(agentId, versionData);

      // Pass the new agent version to the parent component
      if (newAgentVersion) {
        onNewAgentVersion(newAgentVersion);
        // Close the modal
        onClose();
      }
    } catch (error) {
      console.error("Error creating version:", error);
    }
  };

  return (
    <>
      <Button onClick={onOpen}>New version</Button>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create new version</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Version name</FormLabel>
              <Input
                ref={initialRef}
                value={versionName}
                placeholder={`${latestVersion.name || "Version"} ${
                  agentVersions.length + 1
                }`}
                onChange={(e) => setVersionName(e.target.value)}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Description</FormLabel>
              <Input
                value={description}
                placeholder="This is a new version"
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Based on</FormLabel>
              <Select
                placeholder="Select version"
                value={selectedVersionId || ""}
                onChange={(e) => setSelectedVersionId(e.target.value)}
              >
                {agentVersions.map((agentVersion) => (
                  <option key={agentVersion.id} value={agentVersion.id}>
                    {agentVersion.is_default
                      ? agentVersion.name + " (Default)"
                      : agentVersion.name}
                  </option>
                ))}
                <option value="engine-prompt">
                  Use engine prompt ({engine?.id})
                </option>
              </Select>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={onClose}
              colorScheme="brand"
              variant="outline"
              isDisabled={loading}
              mr={3}
            >
              Cancel
            </Button>
            <Button
              colorScheme="brand"
              onClick={handleSave}
              isDisabled={
                loading || !versionName || !description || !selectedVersionId
              }
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
