import { EditIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Spinner,
  Table,
  TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { AgentsList } from "../../client/types";
import { BOT_API_URL } from "../../utils/constants";
import { loadToken } from "../../utils/validateToken";
import { useRoleAuth } from "../../hooks/useRoleAuth";

export const MyAgentsTable = ({
  agents,
  ...props
}: { agents: AgentsList[] } & TableProps) => {
  const navigate = useNavigate();
  const { hasPermission } = useRoleAuth();

  const goToAgentChat = (id: string) => {
    const agent_name = agents.find((agent) => agent.default_version.id === id)?.name;
    // Is the redirect URI cross-domain?
    const isCrossDomain =
      new URL(BOT_API_URL).origin !== window.location.origin;

    if (isCrossDomain) {
      // Use hash for cross-domain with token
      window.open(
        `${BOT_API_URL}/${id}#access_token=${loadToken()}`,
        "_blank"
      );
    } else {
      // Keep using secure cookie for same-domain
      window.open(
        `${BOT_API_URL}/${id}`,
        "_blank"
      );
    }
  };

  const goToAgent = (id: string, setup = false) => {
    const url = setup ? `/agents/${id}?setup=true` : `/agents/${id}`;
    navigate(url);
  };

  if (agents.length === 0) {
    return (
      <Center height="100vh" width="70vw">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
        <Text ml={4}>Loading your agents...</Text>
      </Center>
    );
  }

  return (
    <Box width="70vw" maxH="80vh" overflowY="auto">
      <Table {...props}>
        <Thead position="sticky" top={0} bg="white" zIndex={1}>
          <Tr>
            <Th>
              <Text>Agent Name</Text>
            </Th>
            <Th>{hasPermission(['ADMIN', 'SUPER_ADMIN']) ? 'Default Version' : 'Version'}</Th>
            <Th>Description</Th>
            {hasPermission(['ADMIN', 'SUPER_ADMIN']) && (
              <Th>Engine</Th>
            )}
            {hasPermission(['ADMIN', 'SUPER_ADMIN']) && (
              <Th>Actions</Th>
            )}
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {agents.map((agent: AgentsList) => (
            <Tr key={agent.id}>
              <Td>
                <Button variant="link" onClick={() => goToAgentChat(agent.id)}>
                  {agent.name}
                </Button>
              </Td>
              <Td>
                <Text color="gray.600">
                  {agent.default_version?.name || "Default"}
                </Text>
              </Td>
              <Td>
                <Text color="gray.600">
                  {agent.default_version?.description || "A helpful agent"}
                </Text>
              </Td>
              {hasPermission(['ADMIN', 'SUPER_ADMIN']) && (
                <Td>
                  <Text color="gray.600">
                    {agent.engine?.id || "Unknown Engine"}
                  </Text>
                </Td>
              )}
              {hasPermission(['ADMIN', 'SUPER_ADMIN']) && (
                <Td>
                  <Button
                    variant="link"
                    color="purple.500"
                    onClick={() => goToAgent(agent.id)}
                  >
                    <EditIcon />
                  </Button>
                </Td>
               )}
              <Td></Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};
