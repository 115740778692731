import { useToast } from "@chakra-ui/react";
import { useEffect, useState, useCallback } from "react";
import { AgentVersion } from "../client/types";
import { API_URL } from "../utils/constants";
import { loadToken } from "../utils/validateToken";

export const useGetAgentVersion = (agentId: string, agentVersionId: string) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const toast = useToast();

  const token = loadToken() || ""; // Load token from local storage

  // Rename state to avoid conflict with the AgentVersion type
  const [fetchedAgentVersion, setFetchedAgentVersion] = useState<AgentVersion | null>(
    null
  );

  const getAgentVersion = useCallback(async () => {
    if (fetchedAgentVersion) {
      // Return cached agent version if it's already fetched
      return fetchedAgentVersion;
    }
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `${API_URL}/api/v1/agents/versions/${agentVersionId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch agent version");
      }

      const data = await response.json();
      setFetchedAgentVersion(data);

      return data;
    } catch (error: any) {
      setError(error.message || "An error occurred");
      toast({
        title: "Error fetching agent version",
        description: error.message || "An error occurred",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }, [agentId, agentVersionId, token, fetchedAgentVersion, setFetchedAgentVersion, setLoading, setError, toast]);

  // Fetch the agent version when component mounts or when agentVersionId or agentId changes
  useEffect(() => {
    if (agentId && agentVersionId) {
      getAgentVersion();
    }
  }, [agentId, agentVersionId, getAgentVersion]); // Add getAgentVersion to dependencies

  return { getAgentVersion, fetchedAgentVersion, loading, error };
};
