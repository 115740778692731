import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  StackDivider,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Agent as AgentType } from "../../client/types";
import { useDeleteAgent } from "../../hooks/useDeleteAgent";
import { useUpdateAgent } from "../../hooks/useUpdateAgent";

export const AgentSetup = ({
  Agent,
  onChange,
}: {
  Agent: AgentType;
  onChange: (newName: string) => void;
}) => {
  const agentId = Agent.id;
  const [newAgentName, setAgentName] = useState(Agent.name);
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [inputAgentId, setInputAgentId] = useState("");
  const cancelRef = useRef<HTMLButtonElement | null>(null);
  const { deleteAgent, loading: deleteLoading } = useDeleteAgent();
  const { updateAgent, loading: updateLoading } = useUpdateAgent();
  const agents = JSON.parse(localStorage.getItem("agents") || "[]");
  const handleDelete = async () => {
    if (inputAgentId === "DELETE") {
      const success = await deleteAgent(agentId);
      if (success) {
        onClose();
        toast({
          title: "Agent deleted.",
          description: `The agent ${Agent.name} has been deleted successfully.`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        const updatedAgents = agents.filter((agent: AgentType) => agent.id !== agentId);
        localStorage.setItem("agents", JSON.stringify(updatedAgents));
        navigate("/home");
      }
    } else {
      toast({
        title: "Error",
        description: "Please enter 'DELETE' to confirm deletion.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleUpdateAgent = async () => {
    if (newAgentName) {
      await updateAgent(agentId, newAgentName);
      onChange(newAgentName);
      navigate("/agents/" + agentId);
    } else {
      toast({
        title: "Error",
        description: "Failed to update agent name.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const goBack = () => {
    navigate(`/agents/${agentId}?setup=false`);
  };

  return (
    <Container py={{ base: "4", md: "8" }} minH="80vh" minW="135vh">
      <Stack spacing="5">
        <Stack
          spacing="4"
          direction={{ base: "column", sm: "row" }}
          justify="space-between"
        ></Stack>
        <Stack spacing="5" divider={<StackDivider />}>
          <FormControl id="name">
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: "1.5", md: "8" }}
              justify="space-between"
            >
              <FormLabel variant="inline">Agent name</FormLabel>
              <Input
                maxW={{ md: "3xl" }}
                defaultValue={Agent.name}
                onChange={(e) => setAgentName(e.target.value)}
              />
            </Stack>
          </FormControl>
          <FormControl id="engine">
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: "1.5", md: "8" }}
              justify="space-between"
            >
              <FormLabel variant="inline">Engine</FormLabel>
              <Input
                type="text"
                maxW={{ md: "3xl" }}
                defaultValue={Agent.engine.id}
                readOnly
                variant="ghost"
                textColor="gray"
              />
            </Stack>
          </FormControl>

          <Flex justify="space-between">
            <Button colorScheme="red" mt="1" onClick={onOpen}>
              Delete agent
            </Button>
            <Flex>
              <Divider orientation="horizontal" variant="ghost" />
              <Button
                mt="1"
                onClick={goBack}
                variant="ghost"
                _hover={{ bg: "transparent" }}
              >
                Cancel
              </Button>
              <Divider orientation="horizontal" variant="ghost" />
              <Button
                mt="1"
                onClick={handleUpdateAgent}
                isDisabled={
                  newAgentName === Agent.name || updateLoading ? true : false
                }
              >
                {updateLoading ? "Saving..." : "Save"}
              </Button>
            </Flex>
          </Flex>
        </Stack>
      </Stack>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Agent
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
              <FormControl mt={4}>
                <FormLabel>Enter DELETE to confirm</FormLabel>
                <Input
                  value={inputAgentId}
                  onChange={(e) => setInputAgentId(e.target.value)}
                />
              </FormControl>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={onClose}
                variant="ghost"
                isDisabled={deleteLoading}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={handleDelete}
                ml={3}
                isDisabled={deleteLoading}
              >
                {deleteLoading ? "Deleting..." : "Delete"}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Container>
  );
};
