import { Flex } from "@chakra-ui/react";
import { MyAgentsHome } from "./MyAgentsHome";
export const AgentsDashboard = () => {
  return (
    <Flex direction="column" minH="100vh" minW="150vh" align="flex-start">
      <Flex flex="1">
        <MyAgentsHome />
      </Flex>
    </Flex>
  );
};
