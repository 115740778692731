import { useToast } from "@chakra-ui/react";
import { useEffect, useState, useCallback } from "react";
import { API_URL } from "../utils/constants";
import { loadToken } from "../utils/validateToken";
export const useListAgentsVersions = (agentId: string) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [agentVersions, setAgentVersions] = useState<any[]>([]); // Local state to store fetched agents
  const toast = useToast();

  const listAgentsVersions = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const token = loadToken() || "";
      if (!token) {
        throw new Error("Session expired");
      }
      const response = await fetch(
        `${API_URL}/api/v1/agents/${agentId}/versions`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch agent versions");
      }

      const data = await response.json();
      setAgentVersions(data); // Cache the data in local state
      return data;
    } catch (error: any) {
      setError(error.message);
      setAgentVersions([]); // Set empty array on error
      toast({
        title: "Failed to get agent versions",
        description: error.message || "Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return []; // Return empty array on error
    } finally {
      setLoading(false);
    }
  }, [agentId, toast]);

  // Optional useEffect to automatically fetch agent versions if not loaded yet
  useEffect(() => {
    if (agentVersions.length === 0) {
      listAgentsVersions(); // Fetch agents if not already cached
    }
  }, [agentVersions.length, listAgentsVersions]); // Add dependencies for proper refetching

  const refetch = useCallback(async () => {
    setAgentVersions([]); // Clear the cache
    return listAgentsVersions(); // Fetch fresh data
  }, [listAgentsVersions]);

  return { listAgentsVersions, error, loading, agentVersions, refetch }; // Expose refetch function
};
