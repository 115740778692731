import { SettingsIcon } from "@chakra-ui/icons";
import { Box, Center, Flex, IconButton, Spinner, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FiUpload } from "react-icons/fi"; // Import an upload icon from react-icons
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFeatureFlags } from "../../hooks/useFeatureFlags";
import { useGetAgent } from "../../hooks/useGetAgent";
import { AgentVersions } from "../AgentVersions/AgentVersions";
import { KnowledgeBaseTable } from "../KnowledgeBase/KnowledgeBaseTable";
import { AgentSetup } from "./AgentSetup";

export const AgentHome = () => {
  const { id } = useParams();
  const agentId = id as string;
  const location = useLocation();
  const navigate = useNavigate();

  const { agent, loading } = useGetAgent(agentId);
  const { flags } = useFeatureFlags();
  const [currentAgentData, setCurrentAgentData] = useState(agent);
  const [showAgentKnowledgeBase, setShowAgentKnowledgeBase] = useState(false); // State to toggle Knowledge Base visibility

  const queryParams = new URLSearchParams(location.search);
  const showSetup = queryParams.get("setup") === "true";

  useEffect(() => {
    if (agent) setCurrentAgentData(agent);
  }, [agent]);

  const handleNameUpdate = (newName: string) => {
    if (currentAgentData) {
      setCurrentAgentData({ ...currentAgentData, name: newName });
    }
  };

  const toggleSetup = () => {
    const newShowSetup = !showSetup;
    queryParams.set("setup", newShowSetup.toString());
    navigate({ search: queryParams.toString() });
  };

  if (!currentAgentData || loading) {
    return (
      <Center height="100vh" width="70vw">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
        <Text ml={4}>Loading your agent.</Text>
      </Center>
    );
  }

  return (
    <Flex
      direction="column"
      minH="80vh"
      minW="60vw"
      maxH="100vh"
      overflowY="auto"
    >
      <Flex
        justify="space-between"
        align="center"
        py={4}
        px={6}
        borderBottom="1px solid #e2e8f0"
      >
        <Text fontSize="xl" fontWeight="bold">
          {currentAgentData?.name ?? "Agent"}
        </Text>

        <Flex align="center">
          {flags?.showAgentKnowledgeBase ? (
            <Box position="relative" mr={2}>
              <IconButton
                aria-label="Upload"
                icon={<FiUpload />}
                size="md"
                variant="ghost"
                onClick={
                  setShowAgentKnowledgeBase
                    ? () => setShowAgentKnowledgeBase((prev) => !prev)
                    : undefined
                }
                isDisabled={showSetup}
              />

              <Text
                position="absolute"
                top="24px"
                right="-8px"
                fontSize="xx-small"
                color="purple.500"
                fontWeight="bold"
                hidden={showSetup}
              >
                Beta
              </Text>
            </Box>
          ) : null}

          {!showSetup && (
            <IconButton
              aria-label="Toggle Setup"
              icon={<SettingsIcon />}
              onClick={toggleSetup}
              size="md"
              variant="ghost"
              isDisabled={showAgentKnowledgeBase}
            />
          )}
        </Flex>
      </Flex>

      <Flex direction="column" px={6} py={4}>
        {showSetup ? (
          <AgentSetup Agent={currentAgentData} onChange={handleNameUpdate} />
        ) : showAgentKnowledgeBase ? (
          <KnowledgeBaseTable agent_id={agentId} />
        ) : (
          <AgentVersions id={agentId} agentName={
            currentAgentData.name
          } />
        )}
      </Flex>
    </Flex>
  );
};
