import { ChakraProvider } from "@chakra-ui/react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { Admin } from "./pages/Admin";
import { Alerts } from "./pages/Alerts";
import { Analytics } from "./pages/Analytics";
import { Agent } from "./pages/Agent";
import { FileUpload } from "./pages/FileUpload";
import { Home } from "./pages/Home";
import { Integrations } from "./pages/Integrations";
import { Login } from "./pages/Login";
import { Settings } from "./pages/Settings";
import { myTheme } from "./theme";
import { FeatureFlagsProvider } from "./hooks/useFeatureFlags";
import { loadToken } from "./utils/validateToken";
import { useRoleAuth } from "./hooks/useRoleAuth";

// Segment tracking
declare global {
  interface Window {
    analytics: any;
  }
}

interface ProtectedRouteProps {
  children: React.ReactNode;
  allowedRoles?: Array<'USER' | 'ADMIN' | 'SUPER_ADMIN'>;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, allowedRoles = [] }) => {
  const token = loadToken();
  const { hasPermission } = useRoleAuth();
  
  if (!token) {
    return <Navigate to="/login" replace />;
  }

  if (allowedRoles.length > 0 && !hasPermission(allowedRoles)) {
    return <Navigate to="/home" replace />;
  }

  return <>{children}</>;
};

function App() {
  return (
    <ChakraProvider theme={myTheme}>
      <FeatureFlagsProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/home"
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route
              path="/integrations"
              element={
                <ProtectedRoute allowedRoles={['ADMIN', 'SUPER_ADMIN']}>
                  <Integrations />
                </ProtectedRoute>
              }
            />
            <Route
              path="/upload"
              element={
                <ProtectedRoute>
                  <FileUpload />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin"
              element={
                <ProtectedRoute allowedRoles={['ADMIN', 'SUPER_ADMIN']}>
                  <Admin />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute allowedRoles={['ADMIN', 'SUPER_ADMIN']}>
                  <Analytics />
                </ProtectedRoute>
              }
            />
            <Route
              path="/agents/:id/:mode?/:assistant_id?"
              element={
                <ProtectedRoute allowedRoles={['ADMIN', 'SUPER_ADMIN']}>
                  <Agent />
                </ProtectedRoute>
              }
            />
            <Route
              path="/alerts"
              element={
                <ProtectedRoute allowedRoles={['ADMIN', 'SUPER_ADMIN']}>
                  <Alerts />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Router>
      </FeatureFlagsProvider>
    </ChakraProvider>
  );
}

export default App;
