import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { API_URL } from "../utils/constants";
import { loadToken } from "../utils/validateToken";


export const useDeleteAgent = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const toast = useToast();
  const [wasDeleted, setWasDeleted] = useState<boolean>(false); // Local state to store deletion status

  const deleteAgent = async (agentId: string) => {
    setLoading(true);
    setError(null);
    try {
      const token = loadToken() || "";
      const response = await fetch(`${API_URL}/api/v1/agents/${agentId}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to delete agent");
      }

      setWasDeleted(true);
      return true;
    } catch (error: any) {
      setError(error.message);
      toast({
        title: "Failed to delete agent",
        description: error.message || "Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return false;
    } finally {
      setLoading(false);
    }
  };

  return { deleteAgent, wasDeleted, loading, error };
};
