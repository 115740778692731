import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { updateAgentVersion as updateAgentVersionType } from "../client/types";
import { API_URL } from "../utils/constants";
import { loadToken } from "../utils/validateToken";

export const useUpdateAgentVersion = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const toast = useToast();

  const updateAgentVersion = async (agent_version_update: updateAgentVersionType) => {
    setLoading(true);
    setError(null);

    try {
      const body = JSON.stringify({
        name: agent_version_update.name,
        description: agent_version_update.description,
        is_default: agent_version_update.is_default,
        instructions: JSON.stringify(agent_version_update.instructions),
      });
      const token = loadToken() || "";
      const response = await fetch(
        `${API_URL}/api/v1/agents/versions/${agent_version_update.version_id}`,
        {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
          },
          body: body,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update agent version");
      }

      const data = await response.json();
      toast({
        title: "Agent version updated",
        description: "The agent version has been updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      return data;
    } catch (error: any) {
      setError(error.message);
      toast({
        title: "Failed to update agent version",
        description: error.message || "Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return { updateAgentVersion, loading, error };
};
