import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { createAgentWithVersion } from "../client/types";
import { loadToken } from "../utils/validateToken";
import { API_URL } from "../utils/constants";

export const useCreateAgent = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const toast = useToast();
  const token = loadToken() || "";

  const createAgent = async (agentData: createAgentWithVersion) => {
    setLoading(true);
    setError(null);

    try {
      // Create payload with agent version
      const agentVersionData: createAgentWithVersion = {
        ...agentData,
        description: "Default version",
        is_default: true,
        instructions: agentData.instructions,
      };

      const agentPayload = {
        agent: {
          organization_id: agentData.organization_id,
          engine_id: agentData.engine_id,
          name: agentData.name,
          avatar: agentData.avatar,
          api_token: agentData.api_token,
        },
        agent_version: agentVersionData,
      };

      const createResponse = await fetch(`${API_URL}/api/v1/agents`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token,
        },
        body: JSON.stringify(agentPayload),
      });

      const data = await createResponse.json();
      if (!createResponse.ok) {
        throw new Error(data.message || "Failed to create agent");
      }

      toast({
        title: "Agent created.",
        description: "The agent has been successfully created.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      return data;
    } catch (error: any) {
      setError(error.message);
      toast({
        title: "Agent creation failed.",
        description: "Failed to create agent. Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return { createAgent, error, loading };
};
